import { Link } from 'gatsby';
import React from 'react';
import { StylableProp } from '../../../utils/stylable-prop';
import { SectionHeading } from '../../section-heading';
import { SectionSubheading } from '../../section-subheading';
import SectionTitle from '../../section-title';
import { RelatedArticle } from '../models';
import * as style from './index.module.scss';

interface RealtedProps {
  articles: RelatedArticle[];
}

export default function RelatedArticles(props: StylableProp<RealtedProps>) {
  const articles = props.articles;

  return (
    <aside className={style.relatedArticles}>
      <div className={style.wrapper + ' container d-flex flex-column justify-content-center'}>
        <SectionTitle className={style.title} title="More from Nuborrow" compact={true} />

        <ul className={style.relatedList}>
          {articles.map((article) => (
            <li key={article.id}>
              <SectionSubheading>{article.categories.join(', ')}</SectionSubheading>
              <SectionHeading compact={true}>
                <Link to={`/learn/${article.slug}`}>{article.title}</Link>
              </SectionHeading>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
}
