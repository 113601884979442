import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ArticlePageQuery } from '../../../graphql-types';
import ArticleView from '../../components/articles/article';
import { Article } from '../../components/articles/models';
import RelatedArticles from '../../components/articles/related';
import Layout from '../../components/layout';

type ArticlePageContext = { slug: string };

export default function ArticlePage(props: PageProps<ArticlePageQuery, ArticlePageContext>) {
  const article = new Article(props.data.article!);

  return (
    <Layout pageTitle={`University | ${article.title}`}>
      <Helmet>
        <title>{article.title} | Nuborrow University</title>
        {article.excerpt && <meta name="description" content={article.excerpt} />}
      </Helmet>

      <ArticleView article={article} />

      {article.related && article.related.length && <RelatedArticles articles={article.related} />}
    </Layout>
  );
}

export const query = graphql`
  query ArticlePage($id: String = "") {
    article: strapiArticle(id: { eq: $id }) {
      id
      slug
      title
      author
      publishedAt
      publishedAtRel: publishedAt(fromNow: true)
      publishedAtFormatted: publishedAt(formatString: "MMMM DD, YYYY")
      childStrapiArticleBodyTextnode {
        childMdx {
          body
          timeToRead
          excerpt(truncate: false, pruneLength: 100)
        }
      }
      banner {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 1440)
          }
        }
        alternativeText
        caption
      }
      categories {
        id
        name
        slug
      }
      related {
        id
        title
        slug
        categories {
          name
          slug
          id
        }
      }
    }
  }
`;
