import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { StylableProp } from '../../../utils/stylable-prop';
import SectionTitle from '../../section-title';
import { Article } from '../models';
import * as style from './index.module.scss';

interface ArticleProps {
  article: Article;
}

export default function ArticleView(props: StylableProp<ArticleProps>) {
  const article = props.article;

  return (
    <article
      className={`${style.article} d-block d-md-flex flex-column align-items-center justify-content-center ${props.className}`}
    >
      <div className={style.wrapper + ' container d-flex flex-column justify-content-center'}>
        <SectionTitle className={`${style.title} text-md-center mb-2`} compact={false}>
          {article.title}
        </SectionTitle>

        <div className={`${style.meta} text-md-center my-2 my-md-4`}>
          <span className="published">{article.publishedAtFormatted}</span> &nbsp; | &nbsp;
          <span className="read-time">{article.timeToRead} min read</span>
        </div>

        {article.bannerImg && (
          <GatsbyImage
            className={`${style.banner} my-2 my-md-4`}
            image={article.bannerImg!}
            alt={article.bannerAlt!}
          />
        )}

        <div className={`${style.content} mt-2 mb-5`}>
          <MDXRenderer>{article.content!}</MDXRenderer>
        </div>
      </div>
    </article>
  );
}
